import * as React from "react";
import TextField from "@mui/material/TextField";
import { MenuItem, Typography } from "@mui/material";
import { BLACK } from "../constants/Colors";
import { ChangeFirstLetter } from "../utils/Functions";

export default function CustomTextField(props) {
  const {
    onValueChange,
    labelName,
    isMultiline,
    placeholder,
    isName,
    isPhoneNumber,
    data,
  } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    if (isName) {
      const re = /^[a-zA-Z\s]*$/;
      if (value === "" || re.test(value)) {
        onValueChange(value);
      }
    } else if (isPhoneNumber) {
      const totalRegex = /^[0-9]{1,11}$/;
      if (value === "" || totalRegex.test(value)) {
        onValueChange(value);
      }
    } else {
      onValueChange(value);
    }
  };

  return (
    <>
      <Typography
        variant={"caption"}
        style={{ lineHeight: 1 }}
        color={"textSecondary"}
      >
        {labelName}
      </Typography>
      <TextField
        margin="dense"
        size={"small"}
        sx={{
          color: "#333",
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          marginTop: "-2px",
        }}
        fullWidth
        variant="outlined"
        placeholder={placeholder}
        onChange={handleChange}
        {...props}
        multiline={isMultiline}
        rows={4}
      >
        {data?.map((value, index) => {
          return (
            <MenuItem
              key={index}
              value={value}
              sx={{
                color: BLACK,
                fontSize: 18,
                "&:hover": {
                  // backgroundColor: BLACK_LIGHT,
                },
              }}
            >
              {ChangeFirstLetter(value)}
            </MenuItem>
          );
        })}
      </TextField>
    </>
  );
}
